import React, { useState } from 'react';
import { Dialog, Select } from 'sw-ui';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import DatePicker from 'react-datetime';

import Button from '../../../../../../components/button/Button';
import Input from '../../../../../../components/input';
import TextArea from '../../../../../../components/textarea';
import ErrorMessage from './components/errorMessage/errorMessage';
import AjaxButton from '../../../../../../components/ajaxButton';

import {
  isPastDate,
  isValidEndDate,
  isValidStartDate,
} from '../../../../../../bi/utils/formatDate';
import { eventValidator } from '../../../../../../bi/utils/eventValidator';

import { CREATE_EVENT_FIELDS, CREATE_EVENT_LABELS } from '../../../../../../bi/constants/trips';

import styles from './createEventDialog.module.css';

const DIALOG_WIDTH = '800px';
const LOCALE = 'ru';

export const CreateEventDialog = ({
  show,
  onClose,
  onCreateEvent,
  loading,
  users,
}) => {
  const [formData, setFormData] = useState({
    [CREATE_EVENT_FIELDS.EVENT_TITLE]: '',
    [CREATE_EVENT_FIELDS.EVENT_LOCATION]: '',
    [CREATE_EVENT_FIELDS.PARTICIPANTS_COUNT]: '',
    [CREATE_EVENT_FIELDS.EVENT_START]: null,
    [CREATE_EVENT_FIELDS.EVENT_END]: null,
    [CREATE_EVENT_FIELDS.EVENT_BUDGET]: '',
    [CREATE_EVENT_FIELDS.COMMENT]: '',
    [CREATE_EVENT_FIELDS.LOGIN]: '',
    [CREATE_EVENT_FIELDS.EVENT_CREATION_DATE]: null,
    [CREATE_EVENT_FIELDS.EVENT_CREATION_TIME]: null,
  });

  const [errors, setErrors] = useState({});

  const [userId, setUserId] = useState(null);

  const handleChange = (e, field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));

    if (errors[field] && value.trim()) {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[field];

        return newErrors;
      });
    }
  };

  const handleSelectChange = (field, userId) => {
    const selectedUser = users.find((user) => user.Id === userId);
    const selectedUserLogin = selectedUser.UserName;

    setUserId(userId);

    handleChange(null, field, selectedUserLogin);
  };

  const handleNumberChange = (e, field, value) => {
    if ((/[^0-9]/).test(value)) return;

    handleChange(e, field, value);
  };

  const handleDateChange = (name, date) => {
    setFormData((prev) => ({
      ...prev,
      [name]: date,
    }));

    if (errors[name]) {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];

        return newErrors;
      });
    }
  };

  const handleSubmit = () => {
    const errors = eventValidator(formData);

    if (Object.keys(errors).length > 0) {
      setErrors(errors);

      return;
    }

    onCreateEvent(formData);
  };

  const classNames = {
    [CREATE_EVENT_FIELDS.EVENT_CREATION_DATE]:
      clsx(errors[CREATE_EVENT_FIELDS.EVENT_CREATION_DATE] && styles.error_border),
    [CREATE_EVENT_FIELDS.EVENT_CREATION_TIME]:
      clsx(errors[CREATE_EVENT_FIELDS.EVENT_CREATION_TIME] && styles.error_border),
  };

  const mappedUsers = users
    ? users.map(({ Id, UserName }) => ({
      label: UserName,
      value: Id,
    }))
    : [];

  return (
    <Dialog
      show={ show }
      showCloseButton
      onClick={ onClose }
      width={ DIALOG_WIDTH }
    >
      <div className={ styles.container }>
        <h2>{ CREATE_EVENT_LABELS.TITLE }</h2>
        <Input
          label={ CREATE_EVENT_LABELS.EVENT_TITLE }
          field={ CREATE_EVENT_FIELDS.EVENT_TITLE }
          value={ formData[CREATE_EVENT_FIELDS.EVENT_TITLE] }
          valid={ errors[CREATE_EVENT_FIELDS.EVENT_TITLE] && errors[CREATE_EVENT_FIELDS.EVENT_TITLE] }
          onChange={ handleChange }
        />
        <Input
          label={ CREATE_EVENT_LABELS.EVENT_LOCATION }
          field={ CREATE_EVENT_FIELDS.EVENT_LOCATION }
          value={ formData[CREATE_EVENT_FIELDS.EVENT_LOCATION] }
          onChange={ handleChange }
          required={ false }
        />
        <Input
          label={ CREATE_EVENT_LABELS.PARTICIPANTS_COUNT }
          field={ CREATE_EVENT_FIELDS.PARTICIPANTS_COUNT }
          value={ formData[CREATE_EVENT_FIELDS.PARTICIPANTS_COUNT] }
          onChange={ handleNumberChange }
          required={ false }
        />
        <div>
          <span>{ CREATE_EVENT_LABELS.EVENT_DATES }</span>
          <div className={ styles.event_dates }>
            <div className={ styles.event_date }>
              <label className={ styles.label }>{ CREATE_EVENT_LABELS.EVENT_START }</label>
              <DatePicker
                className={ styles.date_picker }
                timeFormat={ false }
                value={ formData[CREATE_EVENT_FIELDS.EVENT_START] }
                onChange={ (date) => handleDateChange(CREATE_EVENT_FIELDS.EVENT_START, date) }
                isValidDate={ (current) => isValidStartDate(current, formData[CREATE_EVENT_FIELDS.EVENT_END]) }
                locale={ LOCALE }
                closeOnSelect
              />
              <ErrorMessage error={ errors[CREATE_EVENT_FIELDS.EVENT_START] } />
            </div>
            <div className={ styles.event_date }>
              <label className={ styles.label }>{ CREATE_EVENT_LABELS.EVENT_END }</label>
              <DatePicker
                className={ styles.date_picker }
                timeFormat={ false }
                value={ formData[CREATE_EVENT_FIELDS.EVENT_END] }
                onChange={ (date) => handleDateChange(CREATE_EVENT_FIELDS.EVENT_END, date) }
                isValidDate={ (current) => isValidEndDate(current, formData[CREATE_EVENT_FIELDS.EVENT_START]) }
                locale={ LOCALE }
                closeOnSelect
              />
              <ErrorMessage error={ errors[CREATE_EVENT_FIELDS.EVENT_END] } />
            </div>
          </div>
        </div>
        <Input
          label={ CREATE_EVENT_LABELS.EVENT_BUDGET }
          field={ CREATE_EVENT_FIELDS.EVENT_BUDGET }
          value={ formData[CREATE_EVENT_FIELDS.EVENT_BUDGET] }
          onChange={ handleNumberChange }
          required={ false }
        />
        <TextArea
          className={ styles.comment_input }
          label={ CREATE_EVENT_LABELS.COMMENT }
          value={ formData[CREATE_EVENT_FIELDS.COMMENT] }
          onChange={ (value) => handleChange(null, CREATE_EVENT_FIELDS.COMMENT, value) }
          labelTop
        />
        <div>
          <label className={ styles.label }>{ CREATE_EVENT_LABELS.LOGIN }</label>
          <Select
            className={ styles.select_login }
            theme='light'
            search
            nullable
            items={ mappedUsers }
            value={ userId }
            onChange={ (userId) => handleSelectChange(CREATE_EVENT_FIELDS.LOGIN, userId) }
          />
        </div>
        <ErrorMessage error={ errors[CREATE_EVENT_FIELDS.LOGIN] } />
        <div className={ styles.creation_date_container }>
          <label className={ styles.label }>{ CREATE_EVENT_LABELS.EVENT_CREATION_DATE }</label>
          <DatePicker
            className={ classNames[CREATE_EVENT_FIELDS.EVENT_CREATION_DATE] }
            timeFormat={ false }
            value={ formData[CREATE_EVENT_FIELDS.EVENT_CREATION_DATE] }
            isValidDate={ isPastDate }
            onChange={ (date) => handleDateChange(CREATE_EVENT_FIELDS.EVENT_CREATION_DATE, date) }
            closeOnSelect
          />
          <ErrorMessage error={ errors[CREATE_EVENT_FIELDS.EVENT_CREATION_DATE] } />
        </div>
        <div>
          <label className={ styles.label }>{ CREATE_EVENT_LABELS.EVENT_CREATION_TIME }</label>
          <DatePicker
            className={ classNames[CREATE_EVENT_FIELDS.EVENT_CREATION_TIME] }
            dateFormat={ false }
            value={ formData[CREATE_EVENT_FIELDS.EVENT_CREATION_TIME] }
            onChange={ (date) => handleDateChange(CREATE_EVENT_FIELDS.EVENT_CREATION_TIME, date) }
          />
          <ErrorMessage error={ errors[CREATE_EVENT_FIELDS.EVENT_CREATION_TIME] } />
        </div>
        <div className={ styles.action_buttons }>
          <AjaxButton loading={ loading } label={ CREATE_EVENT_LABELS.CREATE } onClick={ handleSubmit } />
          <Button label={ CREATE_EVENT_LABELS.CANCEL } onClick={ onClose } className={ styles.cancel_button } />
        </div>
      </div>
    </Dialog>
  );
};

CreateEventDialog.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreateEvent: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  users: PropTypes.array.isRequired,
};
