import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import styles from './errorMessage.module.css';

const ErrorMessage = ({ error, className }) => {
  if (!error) return null;

  return <p className={ clsx(styles.error_message, className) }>{ error }</p>;
};

ErrorMessage.propTypes = { error: PropTypes.string, className: PropTypes.string };

export default ErrorMessage;
